export enum IngestProcessingStatus {
  Completed = "completed",
  Uploading = "uploading",
  Created = "created",
  Failed = "failed",
  Aborted = "aborted",
  Paused = "paused",
  InProgress = "inprogress",
  Pending = "pending",
}
