import { DateTime } from "@evercam/shared/types/time"

export enum BimModelType {
  Architectural = "Architectural",
  Structural = "Structural",
  FourD = "4D",
  Mep = "Mep",
}

export type BimSnapshotRequestPayload = {
  model?: string
  layerTimestamp?: string
  apiKey?: string
  apiId?: string
}

export type BimSnapshotResponsePayload = {
  imageUrl?: string
}

export type BimModelRequestPayload = {
  model?: string
  timestamp?: string
  apiKey?: string
  apiId?: string
}

export type BimModelResponsePayload = {
  dates?: [string]
  height?: number
  imageUrl?: string
  model?: string
  models?: [string]
  rotation?: number
  scaleX?: number
  scaleY?: number
  viewerHeight?: number
  viewerWidth?: number
  width?: number
  x?: number
  y?: number
}

export type BimCompareExportRequestPayload = {
  name: string
  cameraExid: string
  snapshotDate: string
  bimDate: string
  embed: string
  compareExid: string
  createAnimation: boolean
  bimTransparency?: number
  model?: string
  layerId?: number
  maskId?: number
  evercamWatermark?: boolean
}

export type BimLayerResponsePayload = {
  id: number
  name: string
  insertedAt: DateTime
  updatedAt: DateTime
  shapes: string
  startAt: DateTime
}

export enum BimUploadStatus {
  Initial = 0,
  Saving = 1,
  Success = 2,
  Failed = 3,
}
