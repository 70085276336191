import {
  MilesightCameraNetworkConfiguration,
  MilesightCameraOsdConfiguration,
  MilesightCameraSdCardInfo,
  MilesightCameraSystemSettings,
  MilesightCameraTimeInfo,
  MilesightCameraVideoConfig,
} from "@evercam/shared/types/devices"
import { TaskStatus } from "@evercam/shared/types/components"
import { DateTime_Z_micros } from "@evercam/shared/types/time"

export enum ExNvrHealthCheckTaskId {
  ExNvrLogin = "exNvrLogin",
  ExNvrConfigCheck = "exNvrGetDeviceInfo",
  ExNvrRecordings = "exNvrGetRecordings",
  CameraSystemConfigCheck = "getCameraSystemInfo",
  CameraTimeConfigCheck = "getCameraTimeInfo",
  CameraStreamsConfigCheck = "getCameraStreamsInfo",
  CameraNetworkConfigCheck = "getCameraNetworkInfo",
  CameraOsdConfigCheck = "getCameraOsdInfo",
  CameraStorageConfigCheck = "getCameraStorageInfo",
}

export type ExNvrHealthCheckTaskResult<T extends ExNvrHealthCheckTaskId> =
  T extends ExNvrHealthCheckTaskId.ExNvrLogin
    ? ExNvrLoginResponse
    : T extends ExNvrHealthCheckTaskId.ExNvrConfigCheck
    ? ExNvrDeviceConfig
    : T extends ExNvrHealthCheckTaskId.ExNvrRecordings
    ? ExNvrRecordingInterval[]
    : T extends ExNvrHealthCheckTaskId.CameraStreamsConfigCheck
    ? MilesightCameraVideoConfig
    : T extends ExNvrHealthCheckTaskId.CameraSystemConfigCheck
    ? MilesightCameraSystemSettings
    : T extends ExNvrHealthCheckTaskId.CameraTimeConfigCheck
    ? MilesightCameraTimeInfo
    : T extends ExNvrHealthCheckTaskId.CameraNetworkConfigCheck
    ? MilesightCameraNetworkConfiguration
    : T extends ExNvrHealthCheckTaskId.CameraOsdConfigCheck
    ? MilesightCameraOsdConfiguration
    : T extends ExNvrHealthCheckTaskId.CameraStorageConfigCheck
    ? MilesightCameraSdCardInfo
    : never

export type ExNvrHealthCheckTask<T extends ExNvrHealthCheckTaskId> = {
  id: T
  status: TaskStatus
  duration?: number
  result?: ExNvrHealthCheckTaskResult<T>
  error?: Error
}

export type ExNvrLoginResponse = { accessToken: string }

export type ExNvrRecordingInterval = {
  active: boolean
  startDate: DateTime_Z_micros
  endDate: DateTime_Z_micros
}

export type ExNvrDeviceConfig = {
  id: string
  name: string
  type: string
  state: string
  credentials: {
    password: string
    username: string
  }
  mac: string
  url: string
  insertedAt: string
  updatedAt: string
  settings: {
    storageAddress: string
    overrideOnFullDisk: boolean
    overrideOnFullDiskThreshold: number
    generateBif: boolean
  }
  vendor: string
  model: string
  streamConfig: {
    filename: string | null
    duration: string | null
    temporaryPath: string | null
    snapshotUri: string
    streamUri: string
    subStreamUri: string
  }
  timezone: string
}

export enum ExNvrFootageAvailableStatus {
  Active = "Active",
  NotActive = "Not Active",
  NotAvailable = "Not Available",
}
