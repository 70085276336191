export enum SmsCommands {
  Cellular = "cellular",
  Gpson = "gpson",
  GPS = "gps",
  IPUnblock = "ipunblock",
  IOStatus = "iostatus",
  IOSet = "ioset",
  MonitoringStatus = "monitoring_status",
  MobileOn = "mobileon",
  MobileOff = "mobileoff",
  Reboot = "reboot",
  Restore = "restore",
  RmsOn = "rms_on",
  RmsConnect = "rms_connect",
  RmsStatus = "rms_status",
  SwitchSIM = "switch_sim",
  Status = "status",
  SshOn = "sshon",
  SshOff = "sshoff",
  Uci = "uci",
  VpnOn = "vpnon",
  VpnOff = "vpnoff",
  VpnStatus = "vpnstatus",
  WifiOn = "wifion",
  WifiOff = "wifioff",
  Wakeup = "wakeup",
  WebOn = "webon",
  WebOff = "weboff",
}

export enum SmsStatuses {
  Delivered = "Delivered",
  Accepted = "accepted",
  Pending = "Pending",
}
