export type StorageServer = string

export type StorageServersResponsePayload = {
  servers: StorageServer[]
}

export enum SnapshotExtractionStatus {
  Completed = "completed",
  Failed = "failed",
  Processing = "processing",
}

export enum SnapshotExtractionType {
  Cloud = "cloud",
  Local = "local",
  Timelapse = "timelapse",
}
