export type AnalyticsRequestPayload = {
  name: string
  cameraExid: string
} & { [key: string]: any }

export enum AnalyticsEventPageId {
  Timeline = "Timeline",
  Copilot = "Copilot",
  Recordings = "Recordings",
  Liveview = "LiveView",
  Compare = "Compare",
  BimCompare = "BimCompare",
  Sharing = "Sharing",
  Timelapse = "Timelapse",
  Project = "Project",
  AllProjects = "AllProjects",
  Tutorials = "Tutorials",
  ProjectMapView = "PorjectMapView",
  AccountMapView = "AccountMapView",
  ProgressPhotos = "ProgressPhotos",
  ProgressPhotosUnsubscribe = "ProgressPhotosUnsubscribe",
  VideoWall = "VideoWall",
  ProjectMembers = "ProjectMembers",
  ProjectOverview = "ProjectOverview",
  ProjectCameraSettings = "ProjectCameraSettings",
  Connectors = "Connectors",
  MediaHub = "MediaHub",
  MediaOveriew = "MediaOveriew",
  GateReport = "GateReport",
  Bim = "Bim",
  Drone = "Drone",
  ThreeSixty = "360",
  SitePlanner = "SitePlannerView",
  Account = "Account",
  Activities = "Activities",
  EmbeddedLive = "EmbeddedLive",
  EmbeddedRecordings = "EmbeddedRecordings",
  EmbeddedBimCompare = "EmbeddedBimCompare",
  GateReportInfo = "GateReportInfo",
  BimInfo = "BimInfo",
  DroneInfo = "DroneInfo",
  ThreeSixtyInfo = "360Info",
  BimCompareInfo = "BimCompareInfo",
}

export enum AnalyticsEvent {
  // Global events
  PageView = "PageView",

  // Layout events
  LayoutToggleRightSidebar = "Layout-ToggleRightSidebar",
  LayoutResizeRightSidebar = "Layout-ResizeRightSidebar",
  LayoutThemeMode = "Layout-ThemeMode",

  // Features toggle events
  Xray = "Xray-Toggle",
  Weather = "Weather-Toggle",
  EditTool = "EditTool-Toggle",
  Comment = "Comment-Toggle",
  Compare = "Compare-Toggle",
  Copilot = "Copilot-Toggle",

  // Player events
  PlayerSelectCamera = "Player-SelectCamera",
  PlayerDownload = "Player-Download",
  PlayerToggleFullscreen = "Player-ToggleFullscreen",
  PlayerEnableLive = "Player-EnableLive",
  PlayerTogglePlayback = "Player-TogglePlayback",
  PlayerSelectSpeed = "Player-SelectSpeed",
  PlayerSelectQuality = "Player-SelectQuality",
  PlayerJumptoNextTicks = "Player-JumptoNextTicks",
  PlayerJumptoPreviousTicks = "Player-JumptoPreviousTicks",
  PlayerSwitchMode = "Player-SwitchMode",
  PlayerZoomIn = "Player-ZoomIn",
  PlayerZoomOut = "Player-ZoomOut",

  // Timeline actions
  TimemlineSelectMilestoneItem = "Timeline-SelectMilestoneItem",
  TimelineClickEvent = "Timeline-ClickEvent",
  TimelineSelectTimestamp = "Timeline-SelectTimestamp",

  // Copilot
  CopilotSendMessage = "Copilot-SendMessage",
  CopilotClickTimestamp = "Copilot-ClickTimestamp",
  CopilotRegenerate = "Copilot-Regenerate",
  CopilotError = "Copilot-Error",
  CopilotNavigation = "Copilot-Navigation",
  CopilotToggle = "Copilot-Toggle",

  // Timeline groups visibitlity events
  GroupsVisibilityMobileCapture = "GroupsVisibility-MobileCapture",
  GroupsVisibilityAnpr = "GroupsVisibility-Anpr",
  GroupsVisibilityComments = "GroupsVisibility-Comments",
  GroupsVisibilityMediaHub = "GroupsVisibility-MediaHub",
  GroupsVisibilityBimMilestones = "GroupsVisibility-BimMilestines",
  GroupsVisibilityDroneFlights = "GroupsVisibility-DroneFlights",
  GroupsVisibility360Walks = "GroupsVisibility-360Walks",
  GroupsVisibilitySafetyReport = "GroupsVisibility-SafetyReport",
  GroupsVisibilityConstructionReport = "GroupsVisibility-ConstructionReport",
  GroupsVisibilitySiteActivity = "GroupsVisibility-SiteActivity",
  GroupsVisibilityExNvrVideoRecordings = "GroupsVisibility-ExNvrVideoRecordings",
  GroupsVisibilityLuminance = "GroupsVisibility-Luminance",

  // Comments events
  CommentsPlaceMarker = "Comments-PlaceMarker",
  CommentsSave = "Comments-Save",
  CommentsCancelSave = "Comments-CancelSave",
  CommentsRemove = "Comments-Remove",
  CommentsCancelRemove = "Comments-CancelRemove",
  CommentsArchive = "Comments-Archive",
  CommentsCancelArchive = "Comments-CancelArchive",
  CommentsSelect = "Comments-Select",

  // Xray events
  XrayMove = "Xray-Move",
  XrayMoveBeforeMarker = "Xray-MoveBeforeMarker",
  XrayMoveAfterMarker = "Xray-MoveAfterMarker",
  XrayToggleExportDialog = "Xray-ToggleExportDialog",
  XrayDownload = "Xray-Download",
  XraySaveToMediaHub = "Xray-SaveToMediaHub",
  XarySelectDate = "Xray-SelectDate",
  XarySelectLatestDate = "Xray-SelectLatestDate",
  XarySelectOldestDate = "Xray-SelectOldestDate",
  XraySendToConnector = "Xray-SendToConnector",
  // Weather events
  WeatherSelectProvider = "Weather-SelectProvider",
  WeatherSelectDate = "Weather-SelectDate",
  WeatherGoBackDate = "Weather-GoBackDate",
  WeatherGoForwardDate = "Weather-GoForwardDate",
  WeatherSelectTempScale = "Weather-SelectTempScale",
  WeatherSelectLegend = "Weather-SelectLegend",

  // Compare events
  CompareSlide = "Compare-Slide",
  CompareMoveBeforeMarker = "Compare-MoveBeforeMarker",
  CompareMoveAfterMarker = "Compare-MoveAfterMarker",
  CompareSelectBeforeDate = "Compare-SelectBeforeDate",
  CompareSelectBeforeLatestDate = "Compare-SelectBeforeLatestDate",
  CompareSelectBeforeOldestDate = "Compare-SelectBeforeOldestDate",
  CompareSelectAfterDate = "Compare-SelectAfterDate",
  CompareSelectAfterOldestDate = "Compare-SelectAfterOldestDate",
  CompareSelectAfterLatestDate = "Compare-SelectAfterLatestDate",
  CompareToggleExportDialog = "Compare-ToggleExportDialog",
  CompareSaveToMediaHub = "Compare-SaveToMediaHub",
  CompareGoToMediaHub = "Compare-GoToMediaHub",
  CompareDownloadAs = "Compare-DownloadAs",
  CompareCopyDownloadFileUrl = "Compare-CopyDownloadFileUrl",

  // Bim Compare events
  BimCompareSlide = "BimCompare-Slide",
  BimCompareSelectCameraDate = "BimCompare-SelectCameraDate",
  BimCompareSelectCameraLatestDate = "BimCompare-SelectCameraLatestDate",
  BimCompareSelectCameraOldestDate = "BimCompare-SelectCameraOldestDate",
  BimCompareSelectBimDate = "BimCompare-SelectBimDate",
  BimCompareSelectBimOldestDate = "BimCompare-SelectBimOldestDate",
  BimCompareSelectBimLatestDate = "BimCompare-SelectBimLatestDate",
  BimCompareSelectModel = "BimCompare-SelectModel",
  BimCompareChangeTransparency = "BimCompare-ChangeTransparency",
  BimCompareSelectTimelineDate = "BimCompare-SelectTimelineDate",
  BimCompareToggleExportDialog = "BimCompare-ToggleExportDialog",
  BimCompareSaveToMediaHub = "BimCompare-SaveToMediaHub",
  BimCompareGoToMediaHub = "BimCompare-GoToMediaHub",
  BimCompareDownloadAs = "BimCompare-DownloadAs",
  BimCompareCopyDownloadFileUrl = "BimCompare-CopyDownloadFileUrl",

  // Edit tool events
  EditToolSelectTool = "EditTool-SelectTool",
  EditToolUndo = "EditTool-Undo",
  EditToolRedo = "EditTool-Redo",
  EditToolSelectColor = "EditTool-SelectColor",
  EditToolApplyCrop = "EditTool-ApplyCrop",
  EditToolCancelCrop = "EditTool-CancelCrop",
  EditToolCancelSend = "EditTool-CancelSend",
  EditToolOpenEditName = "EditTool-OpenEditName",
  EditToolCancelEditName = "EditTool-CancelEditName",
  EditToolSaveEditedName = "EditTool-SaveEditedName",
  EditToolSaveToMediaHub = "EditTool-SaveToMediaHub",
  EditToolDownloadAs = "EditTool-DownloadAs",
  EditToolSendToConnector = "EditTool-SendToConnector",

  // Ai Tools events
  AiToolSelectObjectDetection = "AITool-SelectObjectDetection",
  AiToolSelectAskChatGpt = "AITool-SelectAskChatGpt",
  AiToolSelectSegmentation = "AITool-SelectSegmentation",
  AiToolSelectDepthAnalysis = "AITool-SelectDepthAnalysis",

  // Recordings events
  RecordingsSelectDate = "Recordings-SelectDate",
  RecordingsSelectLatestDate = "Recordings-SelectLatestDate",
  RecordingsSelectOldestDate = "Recordings-SelectOldestDate",
  RecordingsClickSupportLink = "Recordings-ClickSupportLink",

  // Sharing events
  SsharingToggleAddUserDialog = "Sharing-ToggleAddUserDialog",
  SsharingAddUser = "Sharing-AddUser",
  SsharingToggleDeleteUserDialog = "Sharing-ToggleDeleteUserDialog",
  SsharingDeleteUser = "Sharing-DeleteUser",
  SsharingSaveVisibilityOptions = "Sharing-SaveVisibilityOptions",
  SsharingCopyLink = "Sharing-CopyLink",
  SsharingResendInvite = "Sharing-ResendInvite",
  SsharingTransferOwnership = "Sharing-TransferOwnership",
  SsharingSortBy = "Sharing-SortBy",
  SsharingChangeAccessRight = "Sharing-ChangeAccessRight",

  // Timelapse Creator eventsP  TimelapseToggleHelpDialog = "Timelapse-ToggleHelpDialog",
  TimelapseSelectCustomPeriod = "Timelapse-SelectCustomPeriod",
  TimelapseToggleCustomScheduleDialog = "Timelapse-ToggleCustomScheduleDialog",
  TimelapseSaveCustomSchedule = "Timelapse-SaveCustomSchedule",
  TimelapseToggleExportDialog = "Timelapse-ToggleExportDialog",
  TimelapseExportSelectStep = "Timelapse-SelectStep",
  TimelapseSelectOption = "Timelapse-SelectOption",
  TimelapseSaveToMediaHub = "Timelapse-SaveToMediaHub",
  TimelapseGoToMediaHub = "Timelapse-GoToMediaHub",
  TimelapseToggleUploadLogoDialog = "Timelapse-ToggleUploadLogoDialog",
  TimelapseUploadLogo = "Timelapse-UploadLogo",

  // Progress Photos events
  ProgressPhotoToggleCreateDialog = "ProgressPhoto-ToggleCreateDialog",
  ProgressPhotoToggleEditDialog = "ProgressPhoto-ToggleEditDialog",
  ProgressPhotoSelectProvider = "ProgressPhoto-SelectProvider",
  ProgressPhotoSelectMember = "ProgressPhoto-SelectMember",
  ProgressPhotoSelectCameras = "ProgressPhoto-SelectCameras",
  ProgressPhotoSelectDays = "ProgressPhoto-SelectDays",
  ProgressPhotoSelectTime = "ProgressPhoto-SelectTime",
  ProgressPhotoSelectTimezone = "ProgressPhoto-SelectTimezone",
  ProgressPhotoCreate = "ProgressPhoto-Create",
  ProgressPhotoEdit = "ProgressPhoto-Edit",
  ProgressPhotoPause = "ProgressPhoto-Pause",
  ProgressPhotoResume = "ProgressPhoto-Resume",
  ProgressPhotoDelete = "ProgressPhoto-Delete",
  ProgressPhotoUnsubscribeUser = "ProgressPhoto-UnsubscribeUser",

  // Map View events
  MapViewToggleCameraMapInfoTooltip = "MapView-ToggleCameraMapInfoTooltip",
  MapViewClickCameraLink = "MapView-ClickCameraLink",

  // Video Wall events
  VideoWallToggleFullscreen = "VideoWall-ToggleFullscreen",
  VideoWallToggleCameraFullscreen = "VideoWall-ToggleCameraFullscreen",
  VideoWallToggleConfigDialog = "VideoWall-ToggleConfigDialog",
  VideoWallToggleCameraVisibility = "VideoWall-ToggleCameraVisibility",
  VideoWallPositionCameraOnWall = "VideoWall-PositionCameraOnWall",
  VideoWallSaveConfig = "VideoWall-SaveConfig",

  // Project Members events
  ProjectMembersToggleAddDialog = "ProjectMembers-ToggleAddDialog",
  ProjectMembersAddMember = "ProjectMembers-AddMember",
  ProjectMembersShareCamera = "ProjectMembers-ShareCamera",
  ProjectMembersEditCameraAccess = "ProjectMembers-EditCameraAccess",
  ProjectMembersDeleteCameraAccess = "ProjectMembers-DeleteCameraAccess",

  // Connectors events
  ConnectorsConnectToProcore = "Connectors-ConnectToProcore",
  ConnectorsToggleAconexDialog = "Connectors-ToggleAconexDialog",
  ConnectorsConnectToAconex = "Connectors-ConnectToAconex",
  ConnectorsConnectToAutodesk = "Connectors-ConnectToAutodesk",
  ConnectorsToggleVoyageControlDialog = "Connectors-ToggleVoyageControlDialog",
  ConnectorsConnectToVoyageControl = "Connectors-ConnectToVoyageControl",
  ConnectorsToggleConnectorPromptDialog = "Connectors-ToggleConnectorPromptDialog",

  // Media Hub events
  MediaHubSelectFilter = "MediaHub-SelectFilter",
  MediaHubApplyFilters = "MediaHub-ApplyFilters",
  MediaHubToggleAddMediaUrlDialog = "MediaHub-ToggleAddMediaUrlDialog",
  MediaHubToggleUploadFileDialog = "MediaHub-ToggleUploadFileDialog",
  MediaHubSaveMediaUrl = "MediaHub-SaveMediaUrl",
  MediaHubUploadFile = "MediaHub-UploadFile",
  MediaHubSelectPage = "MediaHub-SelectPage",
  MediaHubGoToNextPage = "MediaHub-GoToNextPage",
  MediaHubGoToPreviousPage = "MediaHub-GoToPreviousPage",

  // Media Hub Item events
  MediaDelete = "Media-Delete",
  MediaToggleTitleEditing = "Media-ToggleTitleEditing",
  MediaSaveTitle = "Media-SaveTitle",
  MediaDownloadAs = "Media-DownloadAs",
  MediaTogglePublicVisibility = "Media-TogglePublicVisibility",
  MediaShareToPlatform = "Media-ShareToPlatform",
  MediaCopyMediaLink = "Media-CopyMediaLink",
  MediaGoToMediaHub = "Media-GoBackToMediaHub",

  // Gate report
  GateReportExportPdf = "GateReport-ExportPdf",
  GateReportExportCsv = "GateReport-ExportCsv",

  // TODO: The events bellow are old events, should be updated!
  // Recordings
  CreateClip = "create_clip",

  // Gate Report
  ClickGateReportOpenInRecording = "click_gate_report_open_in_recording",
  FilterGateReportCameras = "filter_gate_report_cameras",
  FilterGateReportVehicleTypes = "filter_gate_report_vehicle_types",
  FilterGateReportEntryExit = "filter_gate_report_entry_exit",
  ClickGateReportTimelineDate = "click_gate_report_timeline_date",
  FilterGateReportPlateNumber = "filter_gate_report_plate_number",

  //Help Menu
  HelpMenuOpenLiveChat = "HelpMenu-OpenLiveChat",
  HelpMenuClickTutorialsLink = "HelpMenu-ClickTutorialsLink",
  HelpMenuClickWhatsNewLink = "HelpMenu-ClickWhatsNewLink",
  HelpMenuClickUserManuaLink = "HelpMenu-ClickUserManuaLink",
  HelpMenuClickSupportTicketsLink = "HelpMenu-ClickSupportTicketsLink",
}
