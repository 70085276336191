import {
  _360Scene,
  Media,
  DroneModel,
  MobileCaptureSnapshot,
  ExNvrHealthCheckTask,
  ExNvrHealthCheckTaskId,
  Comment,
} from "@evercam/shared/types"
import { type TimelineChartType, TimelineEvent, TimelineEventsGroup } from "@evercam/ui"
import { TimelineDataProvider } from "@evercam/shared/components/timelinePlayer/providers"

export enum WatermarkedImageDownloadOrigin {
  Drone = "drone",
  Bim = "BIM",
  Snapshot = "snapshot",
  The360 = "360",
  SitePlanner = "Site planner",
}

export enum PlayerMode {
  Video = "video",
  Jpegs = "jpegs",
}

export enum TaskStatus {
  Idle = "idle",
  Loading = "loading",
  Success = "success",
  Error = "error",
}

export type TasksStatusMap = {
  [key: string]: TaskStatus
}

export type TaskProgressItem = {
  status: TaskStatus
  description: string
  percentDone?: number
  duration?: number
}

export type GlobalSearchMatch = {
  path: Array<string>
  value: string | number | boolean
}

export type GlobalSearchResult = {
  index: number
  matches: Array<GlobalSearchMatch>
}

export type GlobalSearchResultInput = Array<Record<any, any> | Array<any>>

type sortby = string
type sortDirection = "asc" | "desc"

export type SortingUrlParam = `${sortby}|${sortDirection}`

export enum TimelinePrecision {
  Year = "year",
  Month = "month",
  Week = "week",
  Day = "day",
  Hour = "hour",
  Minute = "minute",
  Events = "events",
}

export type TimelineDateInterval = {
  fromDate: string
  toDate: string
}

export type TimelineProviderRequestParams = TimelineDateInterval & {
  precision?: TimelinePrecision
}

export type TimelinePlayerGroupConfig = {
  provider?: TimelineDataProvider
  events?: Array<TimelineEvent>
  getChartType?: (precision: TimelinePrecision) => TimelineChartType
} & Omit<TimelineEventsGroup, "events">

export type TimelinePlayerConfig = {
  [groupName: string]: TimelinePlayerGroupConfig
}

export type ExNvrConfigList = {
  items: ExNvrConfigListItem[]
  isDisabled?: boolean
  gridLayout?: string
}

export type ExNvrConfigListItem = {
  label: string
  labelColor?: string
  value?: string | number
  valueColor?: string
  disabled?: boolean
}

export type ExNvrTaskWithDescription<T extends ExNvrHealthCheckTaskId> =
  ExNvrHealthCheckTask<T> & {
    description: string
    hidden: boolean
  }

export type TimelinePlayerTooltipItem = {
  label?: string
  title?: string
  thumbnailUrl: string
  placeholderThumbnailUrl?: string
  fallbackThumbnailUrl?: string
}

export enum TimelineGroupId {
  ThreeSixty = "w360",
  Drone = "drone",
  Bim = "bim",
  Media = "media",
  Comments = "comments",
  Anpr = "anpr",
  MobileCapture = "mobileCapture",
  ExNvrRecordings = "exNvrRecordings",
  SiteActivity = "siteActivity",
  SafetyReports = "safetyReports",
  ConstructionReports = "constructionReports",
  Luminance = "luminance",
  ObjectInspector = "objectInspector",
}

export type TimelineOverlayType =
  | TimelineGroupId.ThreeSixty
  | TimelineGroupId.Drone
  | TimelineGroupId.Media
  | TimelineGroupId.MobileCapture
  | TimelineGroupId.Bim
  | TimelineGroupId.Comments
  | "compare"

export type TimelineOverlayData<T extends TimelineOverlayType> =
  T extends TimelineGroupId.ThreeSixty
    ? _360Scene
    : T extends TimelineGroupId.Drone
    ? DroneModel
    : T extends TimelineGroupId.Media
    ? Media
    : T extends TimelineGroupId.MobileCapture
    ? MobileCaptureSnapshot
    : T extends TimelineGroupId.Comments
    ? Comment
    : never

export enum TimelineMarkerId {
  Xray = "xray",
  CurrentSnapshot = "current",
  CompareBeforeSnapshot = "compareBefore",
  CompareAfterSnapshot = "compareAfter",
  ObjectInspectorFirstSeen = "firstSeen",
  ObjectInspectorLastSeen = "lastSeen",
}

export type TimelineMediaHubItemsByType = {
  clip?: Media[]
  compare?: Media[]
  edit?: Media[]
  timelapse?: Media[]
  xRay?: Media[]
}
